/* FeedbackForm.scss */
.feedback-container {
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable scrolling */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.download-section {
  width: 100%;
  max-width: 800px;
}

img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}
